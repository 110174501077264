import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import Widget = require("Everlaw/UI/Widget");

/* Defines the base Selector interface, used by radios and all our Selector (menu) widgets. */
abstract class BaseSelector<T> extends FocusContainerWidget implements Widget.WithSettableValue {
    _selected: T = null;
    getSelected() {
        return this._selected;
    }
    resetSelected(): void {
        this._selected = null;
    }
    getValue(): T | string {
        return this.getSelected();
    }
    setValue(elem: T) {
        this.select(elem);
    }
    abstract select(elem: T, silent?: boolean): void;
    setWidth(width: string) {}
}

module BaseSelector {}

export = BaseSelector;
